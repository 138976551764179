<template>
  <div class="container">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-center w-100 flex-wrap">
        <div class="controls mw-50 m-0 px-1">
          <b-form-group
            label="Cohorte"
            label-cols="0"
            label-cols-sm="0"
            label-cols-md="2"
            label-cols-lg="2"
            label-cols-xl="2"
            label-size="sm"
            class="m-0"
          >
            <b-form-select v-model="selected_cohort" size="sm">
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Todas --</b-form-select-option
                >
              </template>
              <template v-for="year in [2019, 2020, 2021]">
                <b-form-select-option :value="year" :key="year">{{
                  year
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            :label="$getVisibleNames('mesh.campus', false, 'Sede')"
            label-cols="0"
            label-cols-sm="0"
            label-cols-md="2"
            label-cols-lg="2"
            label-cols-xl="2"
            label-size="sm"
            class="m-0 p-0"
          >
            <b-form-select
              :options="availableCampuses"
              v-model="selected_campus_id"
              text-field="name"
              value-field="id"
              size="sm"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Todas --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            :label="$getVisibleNames('mesh.faculty', false, 'Facultad')"
            label-cols="0"
            label-cols-sm="0"
            label-cols-md="2"
            label-cols-lg="2"
            label-cols-xl="2"
            label-size="sm"
            class="m-0 p-0"
          >
            <b-form-select
              :options="availableFaculties"
              v-model="selected_faculty_id"
              text-field="name"
              value-field="id"
              size="sm"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Todas --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="controls mw-50 m-0 px-1">
          <b-form-group
            :label="`${$getVisibleNames(
              'manual.actividad_presencial',
              false,
              'Actividad Presencial'
            )}`"
            label-cols="0"
            label-cols-sm="0"
            label-cols-md="2"
            label-cols-lg="2"
            label-cols-xl="2"
            label-size="sm"
            class="m-0 p-0"
          >
            <b-form-select
              :options="availableCareers"
              v-model="selected_career_id"
              text-field="name"
              value-field="id"
              size="sm"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Todas --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Nivel"
            label-cols="0"
            label-cols-sm="0"
            label-cols-md="2"
            label-cols-lg="2"
            label-cols-xl="2"
            label-size="sm"
            class="m-0 p-0"
          >
            <b-form-select :options="levels" v-model="selected_level" size="sm">
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Todos --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            :label="$getVisibleNames('mesh.period', false, 'Período')"
            label-cols="0"
            label-cols-sm="0"
            label-cols-md="2"
            label-cols-lg="2"
            label-cols-xl="2"
            label-size="sm"
            class="m-0 p-0"
          >
            <b-form-select
              :options="availablePeriods"
              v-model="selected_period"
              size="sm"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Todos --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="mb-1">
        <b-button
          size="sm"
          :disabled="!enable_button"
          @click="checkButtonEnabled"
          >Cargar</b-button
        >
      </div>
      <div class="table-container w-100">
        <b-table
          striped
          hover
          :items="filteredMatters"
          :fields="matter_fields"
          small
          class="w-100"
        >
          <template #cell(select)>
            <b-form-checkbox></b-form-checkbox>
          </template>
          <template #cell(campuses)="data">
            <div v-for="campus_id in data.value" :key="campus_id">
              <template
                v-for="campus in campuses.filter((x) => x.id == campus_id)"
              >
                {{ campus.name }}
              </template>
            </div>
          </template>
          <template #cell(career)="data">
            <template
              v-for="career in careers.filter((x) => x.id == data.value)"
            >
              {{ career.name }}
            </template>
          </template>
          <template #cell(egress_profile)="data">
            <template
              v-for="egress_profile in egressProfiles.filter(
                (x) => x.id == data.value
              )"
            >
              {{ egress_profile.professional_title }}
            </template>
          </template>
        </b-table>
      </div>
      <div class="table-container w-100" v-if="data">
        <b-table
          striped
          hover
          small
          responsive
          thead-class="small"
          table-class="small"
          bordered
          head-variant="light"
          :fields="dataFields"
          :items="data"
        >
          <template #cell(rut)="data">
            {{ data.value }}
          </template>
          <template #cell(name)="data">
            {{ data.value }}
          </template>
          <template #cell(last_name)="data">
            {{ data.value }}
          </template>
          <template #cell()="data" v-if="loading_count == 0">
            <template v-if="data.field.key">
              {{
                getPeriod(
                  data.field.key.split(" ")[1],
                  data.item.id,
                  data.item.periods[0].period_id
                )
              }}
            </template>
          </template>
        </b-table>
      </div>
    </div>

    <!-- {{ tablePeriods }}  -->
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { round } from "@/utils/utils";

export default {
  name: "StudentsReports",
  components: {},
  data() {
    return {
      data: null,
      selected_period: null,
      availablePeriods: null,
      data_fields: [
        { key: "rut", label: "RUT" },
        { key: "name", label: "Nombres" },
        { key: "last_name", label: "Apellidos" },
      ],
      loading: false,
      user_matters: [],
      selected_cohort: null,
      selected_campus_id: null,
      selected_faculty_id: null,
      selected_career_id: null,
      selected_egress_profile_id: null,
      selected_level: null,
      levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      enable_button: true,
      loading_count: 0,
    };
  },
  computed: {
    ...mapGetters({
      campuses: names.CAMPUSES,
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      egressProfiles: names.EGRESS_PROFILES,
      modalities: names.MODALITIES,
      regimes: names.REGIMES,
      formationAreas: names.FORMATION_AREAS,
      cycles: names.CYCLES,
      user: "getUser",
      users: names.USERS,
    }),
    matter_fields() {
      return [
        { key: "select", label: "" },
        {
          key: "name",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ),
        },
        {
          key: "campuses",
          label: String(this.$getVisibleNames("mesh.campus", true, "Sedes")),
        },
        {
          key: "career",
          label: String(
            this.$getVisibleNames("mesh.career", false, "Programa")
          ),
        },
        { key: "egress_profile", label: "Título" },
      ];
    },
    availableCampuses() {
      let campuses = [];
      this.availableFaculties.forEach((matter) => {
        matter.campuses.forEach((campus_id) => {
          const campus = this.campuses.find((x) => x.id == campus_id);
          if (!campuses.find((x) => x.id == campus.id)) campuses.push(campus);
        });
      });
      return campuses;
    },
    availableFaculties() {
      let faculties = [];
      this.user_matters.forEach((matter) => {
        const career = this.careers.find((x) => x.id == matter.career);
        if (career) {
          const faculty = this.faculties.find((x) => x.id == career.faculty);
          if (faculty && !faculties.find((x) => x.id == faculty.id)) {
            faculties.push(faculty);
          }
        }
      });
      return faculties;
    },
    availableCareers() {
      let careers = [];
      this.user_matters.forEach((matter) => {
        const career = this.careers.find((x) => x.id == matter.career);
        if (career && !careers.find((x) => x.id == career.id))
          careers.push(career);
      });
      return careers;
    },
    selectedEgressProfile() {
      if (!this.selected_career_id) return null;
      const career = this.careers.find((x) => x.id == this.selected_career_id);
      if (!career) return null;
      const egress_profile = this.egressProfiles.find(
        (x) => x.id == career.egress_profile
      );
      if (!egress_profile) return null;
      return egress_profile;
    },
    filteredMatters() {
      let list = this.user_matters;
      if (this.selected_campus_id) {
        list = list.filter((x) => x.campuses.includes(this.selected_campus_id));
      }
      if (this.selected_faculty_id) {
        list = list.filter((x) => x.faculty == this.selected_faculty_id);
      }
      if (this.selected_career_id) {
        list = list.filter((x) => x.career == this.selected_career_id);
      }
      return list;
    },
    tablePeriods() {
      let periods = [];
      if (this.data && this.data.length > 0) {
        const first_student = this.data[0];
        first_student.periods.forEach((period) => {
          const p = {
            id: period.period_id,
            name: period.period_name,
            matters: period.matters,
          };
          periods.push(p);
        });
      }
      return periods;
    },
    dataFields() {
      let fields = this.data_fields;
      // this.tablePeriods.forEach((period) => {
      //   fields.push({ key: "period " + period.id, label: period.name});
      // });
      this.tablePeriods[0].matters.forEach((matter) => {
        fields.push({
          key: "Matter " + matter.matter_id,
          label: matter.matter_name,
        });
      });

      return this.data_fields;
    },
  },
  methods: {
    checkButtonEnabled() {
      let message = "";
      if (!this.selected_cohort) message += "Debe seleccionar una cohorte.\n";
      if (!this.selected_career_id)
        message += String(
          "Debe seleccionar una" +
            this.$getVisibleNames("mesh.career", false, "Programa") +
            ".\n"
        );
      if (!this.selected_level) message += "Debe seleccionar un nivel.\n";
      if (message.length > 0) {
        this.$swal.fire("", message, "info");
        return false;
      }
      this.fetchCohortReport();
      return true;
    },
    fetchMatters() {
      if (!this.user) return;
      this.loading = true;
      this.$restful.Post("/teaching/get_matters/").then((response) => {
        this.user_matters = response;
        this.loading = false;
        this.user_matters.forEach((matter) => {
          this.$store.dispatch(
            names.FETCH_EGRESS_PROFILE,
            matter.egress_profile
          );
        });
      });
    },
    fetchUsers() {
      this.$store.dispatch(names.FETCH_USER);
    },
    fetchCohortReport() {
      this.loading_count += 1;
      if (!this.selectedEgressProfile) {
        this.$swal.fire(
          "",
          String(
            "No se ha encontrado " +
              this.$getVisibleNames(
                "mesh.egressprofile",
                false,
                "Perfil de Egreso"
              ) +
              " para la " +
              this.$getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ) +
              " seleccionada."
          ),
          "info"
        );
        return;
      }
      this.$restful
        .Get(
          `/evaluation/cohort/?year=${this.selected_cohort}&egress_profile=${this.selectedEgressProfile.id}&level=${this.selected_level}`
        )
        .then((response) => {
          this.loading_count -= 1;
          if (response) this.data = response;
        });
    },
    getPeriod(matter_id, user_id, period_id) {
      const student = this.data.find((x) => x.id == user_id);
      if (student) {
        const period = student.periods.find((x) => x.period_id == period_id);
        if (period) {
          const matter = period.matters.find((x) => x.matter_id == matter_id);
          if (matter) return String(round(matter.average)) + " ";
        }
      }
      return "------";
    },
  },
  watch: {
    user_matters() {
      this.user_matters.forEach((matter) => {
        this.$store.dispatch(names.FETCH_CAREER, matter.career);
        this.$store.dispatch(names.FETCH_EGRESS_PROFILE, matter.egress_profile);
      });
    },
    selected_career_id() {
      let max_level = 10;
      this.levels = [];
      if (this.selectedEgressProfile)
        max_level = this.selectedEgressProfile.semester_amount;
      for (let level = 1; level <= max_level; level++) this.levels.push(level);
    },
  },
  created() {
    this.fetchMatters();
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
  },
};
</script>

<style scoped>
.controls {
  min-width: 25rem;
}
.table-container {
  max-height: 15rem;
  overflow: auto;
}
</style>